<template src="./training.html"></template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "Training",
  data: function () {
    return {
      trainings: [],
    }
  },
  created: function(){
    let self = this;
    Http.request('GET', '/handbook/trainings/widget').then(function (response) {
      self.trainings = response.data;
      self.$emit('created', response.data.length);
    });
  },
  methods: {
    getLink(id){
      return '/frame/' + window.btoa('/login/training#/' + id + '/1')
    }
  }
}
</script>

<style scoped>
</style>
